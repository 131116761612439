import mapboxgl from "mapbox-gl";
import mapboxSdk from "@mapbox/mapbox-sdk/services/geocoding";
mapboxgl.accessToken =
	"pk.eyJ1IjoiaW50ZXJjaXR5c3R1ZGlvIiwiYSI6ImNsa2w3ZjdydTBjM3UzZW8zN3J0cjVkYXUifQ.HMVBPdWYwysZnLg-LE1OWA";
import "mapbox-gl/dist/mapbox-gl.css";

window.csMap = function () {
	return {
		activeMarker: null,
		init(e) {
			const mapContainer = this.$refs.map;
			const initiallatLng = mapContainer.dataset.latlng
				.split(",")
				.reverse();
			const initialZoom = mapContainer.dataset.zoom;
			const mapboxClient = mapboxSdk({
				accessToken: mapboxgl.accessToken,
			});
			const map = new mapboxgl.Map({
				container: mapContainer,
				style: "mapbox://styles/mapbox/light-v10",
				center: initiallatLng,
				zoom: initialZoom,
			});
			const markers = this.$refs.markers.querySelectorAll(".map-marker");
			markers.forEach((marker) => {
				const latLng = marker.dataset.latlng.split(",").reverse();
				new mapboxgl.Marker(marker, {
					anchor: "bottom",
				})
					.setLngLat(latLng)
					.addTo(map);
			});
			map.addControl(new mapboxgl.NavigationControl());
			map.scrollZoom.disable();
			new ResizeObserver(() => {
				map.resize();
			}).observe(this.$refs.map);
		},
	};
};
