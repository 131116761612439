/* Foreach helper func - Todd Motto */
var forEach = function (array, callback, scope) {
  for (var i = 0; i < array.length; i++) {
    callback.call(scope, i, array[i]); // passes back stuff we need
  }
};

if (document.querySelector(".animation")) {
  const animations = document.querySelectorAll(".animation");
  forEach(animations, function (index, element) {
    let frames = element.children;
    let frameCount = frames.length;
    let i = 0;
    setInterval(function () {
      frames[i % frameCount].style.display = "none";
      frames[++i % frameCount].style.display = "block";
    }, 500);
  });
}