import { gsap } from "gsap";
window.tenant = function () {
  return {
    init(e) {
      var tenantTl = gsap.timeline({
        defaults: { duration: 1, ease: "expo.inOut" },
      });
      tenantTl.to("#heading", { opacity: 1, duration: 0 });
      tenantTl.to("#projects .project", { opacity: 1, delay: 0.3, duration: 0, stagger: 0.3 });
    },
  };
};
