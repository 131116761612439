import { gsap } from "gsap";
window.animation = function () {
  return {
    planet(e) {
      var planetTl = gsap.timeline({
        defaults: { ease: "sine.inOut" },
        delay: 1.5,
      });
      planetTl.set("#rings circle", { scale: 0.9, transformOrigin: "50% 50%" });
      planetTl.set(".words", { scale: 1, transformOrigin: "50% 50%" });
      planetTl.to("#rings circle", {
        opacity: 1,
        scale: 1,
        duration: 1,
        transformOrigin: "50% 50%",
        stagger: 0.3,
      });
      planetTl.to(".words", {
        opacity: 1,
        scale: 1,
        duration: 1,
        transformOrigin: "50% 50%",
        stagger: 0.5,
      });
      planetTl.add("planet");
      planetTl.to("#planet-text", { fill: "#f4fe54", duration: 0.5 }, "planet");
      planetTl.to(
        "#planet-ring",
        { stroke: "#f4fe54", duration: 0.5 },
        "planet"
      );
    },
    invest(e) {
      var investTL = gsap.timeline({
        defaults: { ease: "sine.inOut" },
        delay: 1.5,
      });
      investTL.set("#middle-line", {
        scaleX: 0,
        opacity: 1,
        transformOrigin: "50% 50%",
      });
      investTL.set("#top-line", {
        scaleY: 0,
        opacity: 1,
        transformOrigin: "0% 100%",
      });
      investTL.set("#bottom-line", {
        scaleY: 0,
        opacity: 1,
        transformOrigin: "0% 0%",
      });
      investTL.to("#middle-line", {
        scaleX: 1,
        transformOrigin: "50% 50%",
        duration: 1.5,
      });
      investTL.to(".middle-text", { dutation: 1, opacity: 1, stagger: 0.5 });
      investTL.to("#top-line, #bottom-line", { scaleY: 1, duration: 1.5 });
      investTL.to(".top-text", { opacity: 1, stagger: 0.5, ease: "sine.out" });
      investTL.to(".middle-text", { opacity: 0, ease: "sine.out", delay: 1 });
      investTL.to(".second-text", { opacity: 1, stagger: 0.5 });
    },
    timeline(e) {
      var timeTl = gsap.timeline({ defaults: { ease: "sine.inOut" }, delay: 1.5 });
      timeTl.set("#middle-line", {
        scaleX: 0,
        opacity: 1,
        transformOrigin: "50% 50%",
      });
      timeTl.set(".tree", { opacity: 0, y: 10, transformOrigin: "50% 50%" });
      timeTl.set("#top-tree", { y: 10 });
      timeTl.set("#bottom-tree", { y: -10 });
      timeTl.to("#middle-line", {
        scaleX: 1,
        transformOrigin: "50% 50%",
        duration: 1.5,
      });
      timeTl.to(".middle-text", { opacity: 1, stagger: 0.5 });
      timeTl.to(".tree", { opacity: 1, scale: 1, y: 0, duration: 2 });
      timeTl.to("#words g", { opacity: 1, duration: 1, stagger: 0.5 });
    },
  };
};
