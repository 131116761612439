import { gsap } from "gsap";
window.intro = function () {
  return {
    init(e) {
      var introTl = gsap.timeline({defaults: { duration: 1, ease: "expo.inOut" }});
      introTl.to("#first-word", { opacity: 1, duration: 0.2 });
      introTl.to("#last-word,#logo", { opacity: 1, duration: 0.2, delay: 0.2 });
      introTl.to("#middle-text", { opacity: 1 });
    },
  };
};
