import Swiper from "swiper";
import { FreeMode } from "swiper/modules";

window.csCarousel = function () {
	return {
		activeSlide: 1,
		init(e) {
			const swiper = new Swiper(this.$refs.carousel, {
				spaceBetween: 75,
				autoWidth: true,
				slidesPerView: "auto",
				grabCursor: true,
				touchEventsTarget: "container",
				freeMode: {
					enabled: true,
				},
				modules: [FreeMode],
			});
			swiper.on("slideChange", () => {
				this.activeSlide = swiper.realIndex + 1;
			});
		},
	};
};
