import { gsap } from "gsap";
window.csMasthead = function () {
	return {
		init(e) {
			gsap.set("#title", { opacity: 0, color: "#000" });
			gsap.set("#image", { opacity: 0 });
			const tl = gsap.timeline({
				defaults: { duration: 1, ease: "expo.inOut" },
			});
			tl.to("#title", { opacity: 1 });
			tl.to("#image", { opacity: 1 });
			tl.to("#title", { color: "#f8f4e9" }, "<");
		},
	};
};
