import { gsap } from "gsap";
window.landing = function () {
	return {
		init(e) {
			gsap.set("#logo", { opacity: 0 });
			gsap.set("#b-corp", { opacity: 0 });

			var landingTl = gsap.timeline({
				defaults: { duration: 1, ease: "expo.inOut" },
				onComplete: openMenu,
			});
			var words = document.getElementsByClassName("word");
			for (var i = 0; i < words.length; i++) {
				landingTl.to(words[i], {
					visibility: "visible",
					duration: 0.5,
				});
				landingTl.to(words[i], { visibility: "hidden", duration: 0 });
			}
			landingTl.to("#home-logo", { opacity: 1, duration: 1, delay: 0.1 });
			landingTl.to("#home-logo,#home-bg", { opacity: 0, duration: 1 });

			document.getElementById("toggle-menu").classList.add("hidden");

			//landingTl.to("#first-word", { opacity: 1, duration: 0.2 });
			// landingTl.to("#last-word,#logo", { opacity: 1, duration: 0.2, delay: 0.2 });
			//landingTl.to("#middle-text", { opacity: 1 });

			function openMenu() {
				setTimeout(function () {
					document.getElementById("toggle-menu").click();

					gsap.to("#logo", {
						opacity: 1,
						duration: 0.3,
						delay: 1,
					});
					gsap.to("#b-corp", {
						opacity: 1,
						duration: 0.3,
						delay: 1,
					});
				}, 500);
			}
		},
	};
};
