import { gsap } from "gsap";
window.team = function () {
  return {
    init(e) {
      var introTl = gsap.timeline({
        defaults: { duration: 1, ease: "expo.inOut" },
      });
      introTl.to("#first-word", { opacity: 1, duration: 0.2 });
      introTl.to("#last-word,#logo", { opacity: 1, duration: 0.2, delay: 0.2 });
      introTl.to("#middle-text", { opacity: 1 });
      introTl.to("#intro", { autoAlpha: 0, delay: 1 });
    },
  };
};
