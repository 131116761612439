if (document.querySelector(".sectionHeadings")) {
	
	// smooth scroll 
	let anchorlinks = document.querySelectorAll('a[href^="#"]');
	for (let item of anchorlinks) {
		item.addEventListener("click", (e) => {
			let hashval = item.getAttribute("href");
			let target = document.querySelector(hashval);
			target.scrollIntoView({
				behavior: "smooth",
				block: "start",
			});
			history.pushState(null, null, hashval);
			e.preventDefault();
		});
	}
	
	// throttle helper
	const throttle = (func, limit) => {
		let lastFunc;
		let lastRan;
		return function () {
			const context = this;
			const args = arguments;
			if (!lastRan) {
				func.apply(context, args);
				lastRan = Date.now();
			} else {
				clearTimeout(lastFunc);
				lastFunc = setTimeout(function () {
					if (Date.now() - lastRan >= limit) {
						func.apply(context, args);
						lastRan = Date.now();
					}
				}, limit - (Date.now() - lastRan));
			}
		};
	};

	// add/remove active state to links
	const links = document.querySelectorAll(".sectionLink");
	const sections = document.querySelectorAll("section");
	function changeLinkState() {
		// add active state
		let index = sections.length;
		while (--index && window.scrollY + 50 < sections[index].offsetTop) {}
		links.forEach((link) => link.classList.remove("active"));
		links[index].classList.add("active");
		// set hash val whille scrolling
		// let hashval = links[index].getAttribute("href");
		// history.pushState(null, null, hashval);
	}
	changeLinkState();
	window.addEventListener("scroll", throttle(changeLinkState, 500));

}
