import { gsap } from "gsap";
window.menu = function () {
	var menu = document.getElementById("menu");
	var menuButton = document.getElementById("toggle-menu");
	var logoFill = document.getElementById("logo-fill");
	var logo = document.getElementById("logo");
	return {
		open: false,
		toggleMenu(e) {
			this.open = !this.open;
			if (menu.classList.contains("hidden")) {
				// menu button
				menuButton.classList.remove("text-tint1");
				menuButton.classList.add("text-tint2");
				menuButton.classList.add("bg-tint5");
				// body class
				document.body.classList.add("fixed");
				// logo
				logo.classList.remove("hidden");
				logoFill.classList.remove("fill-tint1");
				logoFill.classList.add("fill-tint2");
				// menu
				menu.classList.remove("hidden");
				menu.classList.add("flex");
				var menuTl = gsap.timeline();
				menuTl.set("#menu li", { opacity: 0 });
				if (document.querySelector("#tenentMenu")) {
					menuTl.set("#tenentMenu", { opacity: 0 });
				}
				menuTl.to("#menu li", {
					opacity: 1,
					duration: 0,
					stagger: 0.3,
				});
				if (document.querySelector("#tenentMenu")) {
					menuTl.to("#tenentMenu", {
						opacity: 1,
						duration: 0,
						delay: 0.3,
					});
				}
			} else {
				// button
				e.target.innerHTML = "Menu";
				e.target.classList.remove("bg-tint5");
				e.target.classList.remove("text-tint2");
				e.target.classList.add("text-tint1");
				// body
				document.body.classList.remove("fixed");
				// logo
				logo.classList.add("hidden");
				logoFill.classList.remove("fill-tint2");
				logoFill.classList.add("fill-tint1");
				// menu
				menu.classList.add("hidden");
				menu.classList.remove("flex");
				gsap.set("#menu li", { opacity: 0 });
				if (document.querySelector("#tenentMenu")) {
					gsap.set("#tenentMenu", { opacity: 0 });
				}
				/// horrbile - tidy up
				// if (document.body.className.match("home")) {
				//   e.target.classList.add("text-tint2");
				//   e.target.classList.remove("text-tint1");
				// }
				// if (document.body.className.match("projects")) {
				//   e.target.classList.add("text-tint1");
				//   e.target.classList.remove("text-tint2");
				// }
				// if (document.body.className.match("why")) {
				//   e.target.classList.add("text-tint1");
				//   e.target.classList.remove("text-tint2");
				// }
				// if (document.body.className.match("people")) {
				//   e.target.classList.add("text-tint1");
				//   e.target.classList.remove("text-tint2");
				// }
				// if (document.body.className.match("news")) {
				//   e.target.classList.add("text-tint1");
				//   e.target.classList.remove("text-tint2");
				// }
				// if (document.body.className.match("post")) {
				//   e.target.classList.add("text-tint1");
				//   e.target.classList.remove("text-tint2");
				// }
				// if (document.body.className.match("contact")) {
				//   e.target.classList.add("text-tint1");
				//   e.target.classList.remove("text-tint2");
				// }
				// if (document.body.className.match("principle")) {
				//   e.target.classList.add("text-tint1");
				//   e.target.classList.remove("text-tint2");
				// }
				// if (document.body.className.match("msub")) {
				//   e.target.classList.add("text-tint1");
				//   e.target.classList.remove("text-tint2");
				// }
				// if (document.body.className.match("manifesto")) {
				//   e.target.classList.add("text-tint1");
				//   e.target.classList.remove("text-tint2");
				// }
			}
		},
	};
};
